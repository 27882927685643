.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px
  }
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.inset-0 {
  inset: 0px
}

.z-10 {
  z-index: 10
}

.mt-2 {
  margin-top: 0.5rem
}

.mt-4 {
  margin-top: 1rem
}

.block {
  display: block
}

.flex {
  display: flex
}

.grid {
  display: grid
}

.h-28 {
  height: 7rem
}

.w-\[250px\] {
  width: 250px
}

.w-full {
  width: 100%
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.flex-col {
  flex-direction: column
}

.flex-col-reverse {
  flex-direction: column-reverse
}

.items-center {
  align-items: center
}

.justify-center {
  justify-content: center
}

.gap-4 {
  gap: 1rem
}

.rounded {
  border-radius: 0.25rem
}

.border {
  border-width: 1px
}

.border-solid {
  border-style: solid
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity))
}

.bg-\[\#012b57\] {
  --tw-bg-opacity: 1;
  background-color: rgb(1 43 87 / var(--tw-bg-opacity))
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

.p-4 {
  padding: 1rem
}

.p-6 {
  padding: 1.5rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.px-\[20px\] {
  padding-left: 20px;
  padding-right: 20px
}

.pb-4 {
  padding-bottom: 1rem
}

.pb-8 {
  padding-bottom: 2rem
}

.text-center {
  text-align: center
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.font-bold {
  font-weight: 700
}

.font-normal {
  font-weight: 400
}

.font-semibold {
  font-weight: 600
}

.uppercase {
  text-transform: uppercase
}

.italic {
  font-style: italic
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity))
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity))
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

@media (min-width: 640px) {
  .sm\:flex-row {
    flex-direction: row
  }

  .sm\:justify-between {
    justify-content: space-between
  }
}

@media (min-width: 768px) {
  .md\:w-1\/3 {
    width: 33.333333%
  }

  .md\:px-\[50px\] {
    padding-left: 50px;
    padding-right: 50px
  }

  .md\:pb-0 {
    padding-bottom: 0px
  }
}
