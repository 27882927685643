* {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: #f3f3f3;
}

body,
input,
.input,
.select,
.button,
body .react-calendar {
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
}

a {
  color: #012b57;
  cursor: pointer;
  text-decoration: none;
}

h2 {
  color: #012b57;
  font-size: 1.5rem;
}

.mobile-menu {
  display: none;
}

.desktop-menu {
  display: flex;
}

.mobile_only {
  display: none;
}

.header {
  background-color: #012b57;
  height: 80px;
  left: 0;
  line-height: 80px;
  position: fixed;
  width: 100%;
  z-index: 2;
}

.header .wrap {}

.header__link {
  color: #fff;
  display: inline-block;
  font-size: 0.95rem;
  padding: 0 20px;
  position: relative;
}

.header__link:hover {
  background: #001832;
}

.header__submenu .header__link--inner {
  padding-left: 30px;
}

.header__link--active {
  color: #8dc63e;
}

.header__link--mobile {
  display: none;
}

.header__link--desktop {
  display: inline-block;
}

.header__logo {
  height: 80px;
  padding: 0 20px;
}

.header__logo img {
  vertical-align: middle;
}

.header__menu {
  flex: 2;
  text-align: center;
}

.header__menu__section {
  display: inline-block;
  position: relative;
}

.header__menu__section:hover .header__submenu {
  display: block;
}

.header__submenu {
  background: #001832;
  display: none;
  font-size: 0.8rem;
  line-height: normal;
  left: 0;
  position: absolute;
  text-align: left;
  width: 245px;
}

.header__submenu a {
  color: #fff;
  display: block;
  padding: 15px 15px;
}

.header__submenu a.active {
  color: #8dc63e;
}

.header__submenu a:hover {
  background: #012b57;
  color: #fff;
}

.header__submenu__section {
  color: #fff;
  font-size: 0.8rem;
  padding: 15px 20px 10px 20px;
  text-transform: uppercase;
}

.header__submenu hr {
  border-top: 1px solid #012b57;
  height: 1px;
}

.footer {
  background-color: #012b57;
  color: #fff;
  margin: -5px 0 0 0;
  padding: 30px 0;
  position: relative;
  z-index: 2;
}

.footer a {
  color: #fff;
  text-decoration: underline;
}

.footer .wrap {
  display: flex;
  flex-direction: row;
}

.footer__title {
  font-size: 1rem;
  font-weight: 700;
}

.footer__section {
  flex: 1;
  text-align: center;
}

.page {
  min-height: 500px;
}

.page__content {
  background: #fff;
  margin: 0 auto;
  max-width: 1200px;
  padding: 80px 0 0 0;
  position: relative;
  z-index: 1;
}

.wrap {
  margin: 0 auto;
  max-width: 1200px;
}

.cover {
  background-position: center center;
  background-size: cover;
  height: 250px;
  padding: 0 40px;
  position: relative;
}

.cover--big {
  height: 300px;
}

.cover a {
  color: #fff;
}

.cover .button {
  color: #012b57;
}

.cover__shade {
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0.65) 0%,
      rgba(0, 0, 0, 0) 100%);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.cover__content {
  position: relative;
}

.cover__title {
  color: #fff;
  font-size: 2.25rem;
  font-weight: 700;
  padding: 80px 0 20px 0;
}

.cover__search {
  position: absolute;
  right: 0;
  text-align: right;
  top: 50%;
}

.cover__search__input {
  border-radius: 999px;
  font-size: 1rem;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 20px;
  width: 280px;
}

.cover__search .button {
  background: #012b57 url(../public/assets/icons/search.svg) center center no-repeat;
  background-size: 36%;
  border-radius: 999px;
  height: 38px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 1px;
  top: 1px;
  width: 50px;
}

.breadcrumbs {
  color: #fff;
  font-size: 1rem;
}

.breadcrumbs a {
  font-weight: 700;
}

.content {
  padding: 40px;
}

.table-talk-header {
  color: #012b57;
  font-size: 2rem;
  margin: 0 0 15px 0;
  text-align: center;
  text-transform: uppercase;
}

.article {
  margin: 0 0 30px 0;
}

.article h1 {
  color: #012b57;
  font-size: 2rem;
  margin: 0 0 15px 0;
}

.article h2 {
  color: #012b57;
  font-size: 1.8rem;
  margin: 0 0 15px 0;
}

.article h3 {
  font-size: 1.1rem;
  margin: 0 0 10px 0;
}

.article ul {
  margin: 0 0 30px 30px;
}

.article ol {
  margin: 0 0 30px 30px;
}

.article li {
  padding: 5px 0;
}

.article p {
  line-height: 24px;
}

.article a,
.link,
.contact__info__item a {
  color: #42894e;
  font-weight: 700;
}

.article a:hover,
.link:hover,
.contact__info__item a:hover {
  text-decoration: underline;
}

.article .button {
  color: #fff;
  font-weight: 400;
}

.article .button--light {
  color: #012b57;
}

.article .cta {
  text-align: center;
}

.article__separator {
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  margin: 0 -40px 50px -40px;
}

.article .quote {
  font-size: 3rem;
  font-style: italic;
  margin: 80px 0 100px 0;
  text-align: center;
}

.article--page h2 {
  font-size: 1.5rem;
  font-weight: 300;
  margin: 30px 0 40px 0;
  padding: 20px 0 0 0;
  text-align: center;
  text-transform: uppercase;
}

.notice {
  background-color: #f2f2f2;
  border-radius: 10px;
  font-size: 0.9rem;
  margin: 20px 0 30px 0;
  padding: 20px;
}

.content__image {
  display: flex;
  margin: 0 0 50px 0;
}

.content__image__copy {
  flex: 4;
  padding: 20px 30px;
}

.content__image__copy:first-child {
  padding-right: 50px;
}

.content__image__copy:last-child {
  padding-left: 50px;
}

.content__image__photo {
  background-size: cover;
  background-position: center center;
  flex: 3;
}

.form {
  background: #f3f3f3;
  border-radius: 3px;
  padding: 20px 25px;
}

.fieldset {
  padding: 10px 0;
}

.label__container {
  display: inline-block;
  padding: 5px 0 0 0;
  vertical-align: top;
  width: 250px;
}

.input__container {
  display: inline-block;
  position: relative;
}

.payment_input__container {
  width: 400px;
}

.label {
  padding: 0 0 10px 0;
}

.label__info {
  font-size: 0.75rem;
  opacity: 0.6;
}

.input {
  background: #fff;
  border: 1px solid #b1b1b1;
  border-radius: 3px;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 15px;
  width: 400px;
}

.input--area {
  height: 150px;
  line-height: normal;
  padding: 10px 15px;
}

.input--short {
  width: 150px;
}

.input--tiny {
  width: 80px;
}

.input--quantity {
  width: 52px;
}

.input__required {
  color: #c00;
  margin: 0 0 0 5px;
}

.input__prefix {
  left: -80px;
  line-height: 40px;
  position: absolute;
  text-align: right;
  width: 70px;
}

textarea.input {
  height: 150px;
}

.button {
  background: #012b57;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 0.95rem;
  height: 42px;
  line-height: 42px;
  margin: 10px 0 0 0;
  padding: 0 32px;
  text-transform: uppercase;
}

.button--light {
  background: #fff;
  border: 1px solid #012b57;
  color: #012b57;
}

.button--tiny {
  font-size: 18px;
  height: 30px;
  line-height: 28px;
  padding: 0;
  width: 30px;
}

.button--small {
  font-size: 18px;
  height: 30px;
  line-height: 28px;
  padding: 0 16px;
}

.button--online {
  background: #d15757;
}

button[disabled],
button:disabled {
  opacity: 0.5;
  cursor: none;
  pointer-events: none;
}

.photo {
  background-position: center center;
  background-size: cover;
  border-radius: 100px;
  display: inline-block;
  height: 200px;
  width: 200px;
}

.services {
  display: flex;
  margin: 0 0 50px 0;
  padding: 50px 0;
}

.services__item {
  flex: 1;
  padding: 0 30px;
  position: relative;
  text-align: center;
}

.services__item h2 {
  font-size: 1.3rem;
  margin: 0 0 20px 0;
}

.services__item p {
  margin: 0 0 20px 0;
}

.services__item__photo {
  background-position: center center;
  background-size: cover;
  border-radius: 999px;
  display: inline-block;
  height: 220px;
  width: 220px;
  margin: 0 0 20px 0;
}

.about {
  background-image: url(../public/temp/images/home_about.jpg);
  background-size: cover;
  color: #fff;
  padding: 70px 50px;
  text-align: center;
}

.about a {
  color: #fff;
}

.about h2 {
  color: #fff;
  margin: 0 0 20px 0;
}

.about p {
  margin: 0 0 20px 0;
}

.classlist {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 60px 0;
}

.classlist__item {
  flex-basis: 33%;
  padding: 20px;
  text-align: center;
}

.categorylist {
  padding: 60px 0 0 0;
}

.categorylist__item {
  display: flex;
  padding: 0 50px 80px 50px;
  position: relative;
}

.categorylist__item__photo {
  padding: 0 30px 0 0;
}

.categorylist__item__photo .photo {
  background-color: #f2f2f2;
  border-radius: 100px;
  display: inline-block;
  height: 200px;
  width: 200px;
}

.categorylist__item__content {
  flex: 1;
}

.categorylist__item__title {
  font-size: 2rem;
  margin: 0 0 15px 0;
  padding: 0 150px 0 0;
}

.categorylist__item__subtitle {
  font-size: 1rem;
  margin: 0 0 15px 0;
}

.categorylist__item__mark {
  background: #8dc63e;
  border-radius: 999px;
  color: #fff;
  font-size: 0.8125rem;
  padding: 5px 15px;
  position: absolute;
  right: 40px;
  top: 10px;
}

.categorylist__item__description {
  margin: 0 0 15px 0;
}

.preview {
  text-align: center;
}

.preview img {
  width: 100%;
}

.carousel__container {
  height: 450px;
  margin: 0 0 50px 0;
  overflow: hidden;
  position: relative;
}

.carousel__slides {
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.carousel__slide {
  flex: 1;
}

.carousel__content {
  background-size: cover;
  background-position: center center;
  height: 100%;
  position: relative;
  text-align: center;
  width: 100%;
}

.carousel__shade {
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  left: 0;
  padding: 10% 70px 0 70px;
  position: absolute;
  top: 0;
  width: 100%;
}

.carousel__title {
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
  margin: 0 auto 30px auto;
  max-width: 70%;
  text-shadow: 0 0 4px rgba(125, 125, 125, 0.5);
}

.carousel__pages {
  bottom: 20px;
  position: absolute;
  text-align: center;
  width: 100%;
}

.carousel__page {
  background: #fff;
  border-radius: 100px;
  cursor: pointer;
  display: inline-block;
  height: 15px;
  margin: 0 5px;
  opacity: 0.3;
  width: 15px;
}

.carousel__page--active {
  opacity: 1;
}

.details {
  display: flex;
  flex-direction: row-reverse;
}

.details__content {
  flex: 1;
  padding: 0 40px 0 0;
}

.details__content .notice {
  font-size: 0.8rem;
}

.details__content .notice h2 {
  font-size: 1.2rem;
}

.details__content .notice h3 {
  font-size: 1rem;
}

.details__info {
  width: 400px;
  padding: 0 0 20px 0;
}

.menu {
  background: #f2f2f2;
  border-radius: 20px;
  margin: 0 0 40px 0;
  padding: 30px 40px 40px 40px;
}

.menu ul:last-child {
  margin-bottom: 0;
}

.book {
  background: #f2f2f2;
  border-radius: 20px;
  padding: 30px 15px;
  text-align: center;
}

.book__title {
  margin: 0 0 10px 0;
}

.book__price {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0 0 10px 0;
}

.book__price span {
  font-size: 2.625rem;
}

.book__info {
  font-size: 0.8125rem;
  font-style: italic;
  margin: 0 0 20px 0;
}

.book__calendar {
  margin: 0 0 20px 0;
}

.book__events {
  margin: 0 0 15px 0;
}

.book__event {
  background-color: #d8d8d8;
  border-radius: 5px;
  color: #666;
  cursor: pointer;
  display: inline-block;
  margin: 0 10px 10px 0;
  padding: 8px 20px;
}

.book__event:last-child {
  margin-right: 0;
}

.book__event--selected {
  color: #fff;
  background-color: #8dc63e;
}

.book__event--soldout {
  cursor: default;
  opacity: 0.5;
}

.book__event__date {
  font-weight: 700;
  padding: 0 0 4px 0;
}

.book__event__time {
  font-size: 0.9375rem;
  padding: 0 0 4px 0;
}

.book__event__slots {
  font-size: 0.75rem;
  font-style: italic;
}

.book__cta {
  margin: 0 0 30px 0;
}

.book__online {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 0 30px 0;
  text-align: center;
  width: 100%;
}

.book__online__option {
  background-color: #fff;
  border-radius: 10px;
  flex: 1;
  margin: 0 10px 0 0;
  padding: 10px 10px 20px 10px;
}

.book__online__option__copy {
  font-size: 0.7rem;
}

.event__list__item--soldout {
  margin: 0 10px 0 0;
  text-decoration: line-through;
}

.payment {
  margin: 50px 0 100px 0;
  max-width: 700px;
}

.payment__item {
  font-size: 0.95rem;
  margin: 40px 0 0 0;
  overflow: auto;
}

.payment__item p {
  margin: 0 0 20px 0;
}

.payment__thumb {
  background-position: center center;
  background-size: cover;
  border-radius: 999px;
  float: left;
  height: 120px;
  margin: 0 20px 0 0;
  width: 120px;
}

.payment__price {
  font-size: 1.2rem;
  margin: 0 20px 0 0;
}

.payment__total {
  border-top: 1px solid #e6e6e6;
  padding: 20px;
}

.payment__remove {
  background: transparent;
  color: #012b57;
  font-size: 0.8rem;
  margin: 0 0 0 20px;
}

.form .payment__item {
  margin: 20px 0;
}

.form .payment__item h3 {
  margin: 0 0 15px 0;
  padding: 10px 0 0 0;
}

.form .payment__item .info {
  margin: 20px 0 30px 0;
  font-size: 0.8rem;
}

.form .payment__thumb {
  height: 120px;
  width: 120px;
}

.checkout__coupons {
  font-size: 0.9rem;
  display: flex;
}

.checkout__coupons .input {
  font-size: 0.9rem;
  margin: 0 10px 0 0;
  width: 220px;
}

.checkout__coupons .input[disabled] {
  background-color: #ddd;
  border-color: #aaa;
  color: #666;
  cursor: not-allowed;
}

.checkout__coupons .button {
  font-size: 0.9rem;
}

.checkout__coupons__options {
  display: flex;
  flex-direction: column;
  padding: 10px 0 10px 0;
}

.checkout__coupons__options:last-child {
  padding: 10px 0 20px 0;
}

.checkout__coupons__options fieldset {
  padding: 10px 20px 0 0;
}

.dialog-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

dialog {
  z-index: 1000;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  display: none;
  width: 400px;
}

dialog[open] {
  display: block;
}

.job {
  border-bottom: 1px solid #eee;
}

.job__preview {
  border-bottom: 1px solid #eee;
  cursor: pointer;
  display: flex;
  padding: 20px 20px;
}

.job__preview:hover {
  background-color: #f2f2f2;
}

.job__logo {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 999px;
  margin: 0 30px 0 0;
  height: 140px;
  width: 140px;
}

.job__title {
  flex: 2;
  padding: 10px 0 0 0;
  width: 300px;
}

.job__title h2 {
  font-size: 1.5rem;
}

.job__title p {
  font-size: 0.9rem;
  padding: 4px 0;
}

.job__title strong {
  font-size: 1rem;
  margin: 0 20px 0 0;
}

.job__info {
  padding: 20px 0 0 0;
}

.job__info__type {
  background: #012b57;
  border-radius: 5px;
  color: #fff;
  font-size: 0.9rem;
  padding: 10px 15px;
}

.job__content {
  padding: 20px;
}

.job__content__description {}

.job__apply {
  background: #f8f8f8;
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px 20px;
}

.event__container {
  background: rgba(255, 255, 255, 0.9);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
}

.event {
  background: #fff;
  box-shadow: 0 0 20px #ccc;
  left: 50%;
  margin: 0 0 0 -300px;
  padding: 30px 60px 30px 30px;
  position: fixed;
  top: 120px;
  width: 700px;
}

.event__close {
  cursor: pointer;
  font-size: 32px;
  position: absolute;
  right: 20px;
  top: 15px;
}

.event__content {
  display: flex;
}

.event__photo {
  background: #f2f2f2;
  background-position: center center;
  background-size: cover;
  border-radius: 999px;
  flex-shrink: 0;
  height: 120px;
  margin: 0 25px 0 0;
  width: 120px;
}

.event__description {
  padding: 10px 0 0 0;
}

.event__description h2 {
  margin: 0 0 20px 0;
}

.event__description h4 {
  font-size: 1rem;
  margin: 0 0 5px 0;
}

.event__options .button {
  margin-right: 10px;
}

.contact__map {
  margin: 0 0 60px 0;
}

.contact__info {
  margin: 0 0 80px 0;
}

.contact__info__item {
  display: inline-block;
  padding: 0 60px 0 0;
  vertical-align: top;
}

.contact__info__item:last-child {
  padding: 0;
}

.contact__info__item h3 {
  font-size: 1rem;
  margin: 0 0 10px 0;
}

.social {
  margin: 0 0 60px 0;
  text-align: center;
}

.social a {
  display: inline-block;
  margin: 0 15px;
}

.social a img {
  height: 60px;
  width: 60px;
}

.footer .social {
  margin: 30px 0 10px 0;
}

.cart-preview {
  background-color: #42894e;
  border: 1px solid #fff;
  border-radius: 999px;
  bottom: 20px;
  height: 60px;
  position: fixed;
  right: 20px;
  text-align: center;
  width: 60px;
}

.cart-preview a {
  display: block;
  line-height: 60px;
}

.cart-preview img {
  vertical-align: middle;
  width: 30px;
}

.notice {}

.pulse {
  box-shadow: 0 0 0 rgba(50, 50, 50, 0.4);
  animation: pulse 1.5s infinite;
}

.pulse:hover {
  animation: none;
}

.sq-button-wrapper {
  padding: 20px 0 20px 250px;
}

.sq-button-wrapper button {
  background: #012b57;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 0.95rem;
  height: 42px;
  line-height: 42px;
  margin: 10px 0 0 0;
  padding: 0 32px;
  text-transform: uppercase;
}

.inquiry__intro {
  display: flex;
  margin: 0 0 50px 0;
}

.inquiry_form {
  flex-shrink: 0;
  margin: 0 0 0 50px;
  width: 400px;
}

.inquiry_form h3 {
  margin: 10px 0 20px 0;
  text-align: center;
}

.inquiry_form .input {
  width: 100%;
}

.inquiry_form .label__container {
  display: block;
  width: auto;
}

.inquiry_form .input__container {
  display: block;
  width: auto;
}

.tabs {
  display: flex;
}

.tab {
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
  padding: 20px 0 20px 0;
  text-align: center;
}

.tab--active {
  background: #f3f3f3;
}

.tab h3 {
  font-size: 1.125rem;
  font-weight: 400;
  text-transform: uppercase;
}

.tab__photo {
  background-position: center center;
  background-size: cover;
  border-radius: 999px;
  display: inline-block;
  height: 150px;
  margin: 0 0 10px 0;
  width: 150px;
}

.tab__content {
  background: #f3f3f3;
  border-radius: 5px;
  margin: -5px 0 0 0;
  padding: 50px;
}

.topics ul {
  line-height: 1.625rem;
  padding: 20px 0 0 30px;
}

.admin {
  background: #20a8d8;
  border-radius: 5px;
  bottom: 0;
  left: 0;
  margin: 0 0 -5px -5px;
  position: fixed;
  width: 150px;
}

.admin a {
  color: #fff;
  display: block;
  font-size: 0.8rem;
  padding: 10px 0 15px 0;
  text-align: center;
  text-decoration: none;
}

.upcoming__dates {
  margin: 30px 0 80px 0;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(50, 50, 50, 0.4);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(50, 50, 50, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(50, 50, 50, 0);
  }
}

body .react-calendar {
  border: 0;
  border-radius: 10px;
  width: 100%;
}

body .react-calendar button {
  font-size: 0.75rem;
}

body .react-calendar .react-calendar__navigation__label,
body .react-calendar .calendar__navigation__arrow {
  font-size: 1rem;
}

body .react-calendar__month-view__days__day--weekend {
  color: inherit;
}

body .rbc-calendar {
  height: auto;
}

body .rbc-header {
  background: #012b57;
  color: #fff;
  font-weight: 400;
  padding: 10px;
}

body .rbc-toolbar-label {
  font-weight: 700;
}

body .rbc-toolbar {
  flex-direction: row-reverse;
}

body .rbc-event {
  background: #012b57;
  font-size: 0.75rem;
}

body .rbc-time-view .rbc-row {
  min-height: auto;
}

body .react-calendar__tile.react-calendar__month-view__days__day.active {
  background: #012b57;
  color: #fff;
}

body .rbc-row-content {
  min-height: 220px;
}

body .rbc-row-segment .rbc-event-content {
  white-space: normal;
}

body .rbc-month-row {
  min-height: 200px;
  overflow: visible;
}

.ql-align-center {
  text-align: center;
}

.blog-landing-hero-container {
  background-image: url('../public/assets/covers/blog-hero.jpg');
  height: 60vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero-subscribe {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 10%;
  width: 80%;
  max-width: 1000px;
  background-color: rgba(250, 250, 250, 0.1);
  backdrop-filter: blur(30px);
  padding: 30px 45px;
  border-radius: 4px;
  justify-content: center;
  color: #fff;
}

.hero-subscribe h1 {
  width: fit-content;
}

.subscribe-copy {
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
}

.subscribe-copy p {
  color: #fff;
  width: 300px;
  padding-top: 1rem;
  justify-self: flex-start;
}

.subscribe-box {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  height: fit-content;
  align-self: center;
  justify-self: end;
}

.subscribe-box-input-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.subscribe-box-input-container input {
  background-color: #fff;
  padding-left: 15px;
  border-radius: 4px;
  height: 30px;
}

.subcribe-btn {
  background: #102b54;
  border-radius: 4px;
  border: none;
  text-decoration: none;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  padding: 9px 22px;
  height: 40px;
  align-self: center;
}

.newsletter-message {
  text-align: end;
}

input[type='email'] {
  border: none;
  outline: none;
}

.blog-content-layout {
  margin: 0 auto;
  max-width: 1200px;
  padding-top: 60px;
}

.section-one {
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.section-left {
  width: 60%;
  padding: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
}

.section-right {
  width: 40%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.section-right p {
  padding: 15px 15px 0 0;
}

.right-inner {
  display: flex;
  flex-direction: column;
  min-height: 420px;
}

.tags-container {
  display: flex;
  gap: 0.5rem;
}

.tag {
  border: solid #688a5d 1.5px;
  border-radius: 4px;
  padding: 2px 11px;
  font-size: 10px;
}

.tag2 {
  border: solid #fff 1.5px;
  border-radius: 40px;
  padding: 2px 0;
  font-size: 10px;
  width: 70px;
  text-align: center;
}

.read-on-btn {
  justify-self: flex-end;
  display: flex;
  background-color: #102b54;
  padding: 9px 22px;
  width: 150px;
  border-radius: 4px;
  justify-content: flex-end;
}

.read-on-btn-txt {
  color: #fff;
  margin: auto;
}

.blog-card {
  height: 560px;
  width: 300px;
  margin: 3rem 1rem;
}

.blog-card p {
  padding: 10px 10px 10px 0;
  text-transform: uppercase;
}

.blog-card-img {
  /* background-image: url("../public/assets/images/chef-chop.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  width: 100%;
  height: 60%;
}

.blog-card-title {
  font-size: 30px;
  margin: 5px;
}

.blog-card-subtitle {
  font-size: 20px;
  margin: -15px 5px 5px;
}

.map-blog-section {
  display: grid;
  grid-gap: 5px;
  grid-auto-rows: 580px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.map-blog-section a {
  width: fit-content;
  justify-self: center;
}

.blog-hero-img {
  /* background-image: url("../public/assets/covers/blog-hero.jpg"); */
  height: 60vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.blog-hero-box {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 15%;
  width: 400px;
  background-color: rgba(250, 250, 250, 0.1);
  backdrop-filter: blur(30px);
  padding: 30px 45px;
  border-radius: 4px;
  justify-content: center;
  color: #fff;
}

.blog-post-body-section {
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.blog-post-body-section h2 {
  padding-top: 40px;
}

.blog-post-body-section :nth-child(3) {
  padding-bottom: 3rem;
}

.blog-post-img {
  margin: auto;
  display: block;
  height: 250px;
  width: 380px;
}

.blog-post-header {
  font-size: 52px;
  font-weight: 500;
}

@media screen and (min-width: 821px) and (max-width: 945px) {
  .subscribe-copy p {
    width: 230px;
  }
}

@media screen and (min-width: 768px) and (max-width: 820px) {
  .newsletter-message {
    text-align: center;
    margin-top: 10px;
  }

  .hero-subscribe {
    width: 400px;
    top: 25%;
    left: 5%;
  }

  .subscribe-box {
    flex-direction: column;
    gap: 10px;
  }

  .subscribe-copy {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 300px;
  }

  .subscribe-copy p {
    width: 300px;
  }

  .section-left {
    width: 100%;
    padding: 100px;
  }

  .section-right {
    width: 100%;
    margin: 0;
  }

  .right-inner {
    min-height: 220px;
  }

  .read-on-btn {
    margin-top: 2rem;
  }

  .blog-hero-box {
    /* top: 50%; */
    left: 5%;
    width: 350px;
    padding: 30px 45px;
  }
}

@media only screen and (max-width: 768px) {
  .blog-post-header {
    font-size: 30px;
  }

  .newsletter-message {
    text-align: center;
    margin-top: 10px;
  }

  .table-talk-header {
    margin: 15px 0 -15px 0;
  }

  .blog-landing-hero-container {
    height: 80vh;
  }

  .hero-subscribe {
    width: 300px;
    top: 20%;
    left: 5%;
  }

  .subscribe-copy {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 200px;
  }

  .subscribe-copy p {
    width: 200px;
  }

  .subscribe-box {
    flex-direction: column;
    gap: 10px;
  }

  .section-one {
    display: flex;
    flex-direction: column;
  }

  .section-left {
    width: 100%;
    padding: 100px;
  }

  .section-right {
    width: 100%;
    margin: 0;
  }

  .section-right p {
    padding: 15px 15px 0 0;
  }

  .right-inner {
    min-height: 320px;
  }

  .read-on-btn {
    margin-top: 2rem;
  }

  .blog-hero-img {
    height: 40vh;
  }

  .blog-hero-box {
    top: 40%;
    left: 5%;
    width: 250px;
    padding: 15px 20px;
  }

  .blog-post-img {
    height: auto;
    width: 100%;
  }

  .app {
    width: 100%;
  }

  .mobile_only {
    display: inherit;
  }

  .content {
    padding: 15px;
  }

  .form {
    padding: 20px;
  }

  .label__container {
    display: block;
  }

  .input__container {
    display: block;
  }

  .payment_input__container {
    width: unset;
  }

  .input {
    width: 100%;
  }

  .input--quantity {
    width: 52px;
  }

  .footer {
    margin-top: 20px;
  }

  .footer .wrap {
    display: block;
  }

  .footer__section {
    margin-bottom: 20px;
  }

  .sq-button-wrapper button {
    display: block;
    width: auto;
  }

  .services {
    display: block;
    margin-bottom: 0;
  }

  .services__item {
    margin-bottom: 40px;
  }

  .desktop-menu {
    display: block;
    height: 0px;
    overflow: auto;
  }

  .header {
    display: block;
    height: 60px;
    line-height: 60px;
    padding: 60px 0 0 0;
    position: fixed;
  }

  .header__logo {
    left: 20px;
    padding: 0;
    position: absolute;
    top: 0;
  }

  .header__logo img {
    height: 44px;
  }

  .header__menu {
    background: rgba(255, 255, 255, 0.98);
    display: none;
    text-align: center;
  }

  .header__menu--second {
    box-shadow: 0 4px 4px -4px rgba(125, 125, 125, 0.5);
  }

  .header__menu__section {
    display: block;
  }

  .header__submenu {
    background: none;
    display: block;
    position: relative;
    width: auto;
  }

  .header__submenu a {
    color: #012b57;
    text-align: center;
  }

  .header__link {
    border-bottom: 1px solid #e8e8e8;
    color: #012b57;
    display: block;
  }

  .header__link--mobile {
    display: block;
  }

  .header__link--desktop {
    display: none;
  }

  .header__logo .header__link {
    border-bottom: 0;
  }

  .header--open .header__menu {
    display: block;
  }

  .header--open .desktop-menu {
    height: 400px;
  }

  .mobile-menu {
    display: block;
  }

  .page__title {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  .footer__menu {
    display: none;
  }

  .footer__logo img {
    height: 35px;
  }

  .item {
    max-width: 100%;
  }

  .item__content {
    height: auto;
  }

  .item__description {
    margin-bottom: 30px;
  }

  .carousel__container {
    height: 70vw;
    margin: -20px 0 30px 0;
  }

  .carousel__pages {
    bottom: 5px;
  }

  .carousel__page {
    height: 10px;
    width: 10px;
  }

  .carousel__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 50px 20px 35px 20px;
    text-align: center;
  }

  .carousel__title {
    font-size: 1.3rem;
    margin: 0 0 10px 0;
    max-width: none;
    text-align: center;
  }

  .cover {
    height: 60vw;
    margin: -20px 0 0 0;
    padding: 0 30px;
  }

  .cover__title {
    padding: 20px 0;
    font-size: 1.5rem;
  }

  .cover__search {
    position: relative;
    text-align: center;
    top: 20px;
  }

  .cover__search__input {
    width: 100%;
  }

  .mobile-menu {
    position: absolute;
    right: 20px;
    top: 10px;
  }

  .mobile-menu__trigger {
    height: 32px;
    width: 32px;
  }

  .classlist {
    padding: 20px 0;
  }

  .article__separator {
    margin-left: -15px;
    margin-right: -15px;
  }

  .contact__info {
    margin-bottom: 40px;
  }

  .contact__info__item {
    display: block;
    padding: 0 0 30px 0;
  }

  .categorylist__item {
    display: block;
    padding: 0 20px 60px 20px;
  }

  .categorylist__item__photo {
    padding: 0;
  }

  .categorylist__item__title {
    padding-right: 0;
  }

  .categorylist__item__mark {
    display: inline-block;
    margin: 0 0 20px 0;
    position: relative;
    right: 0;
    top: 0;
  }

  .inquiry__intro {
    display: block;
  }

  .inquiry_form {
    margin-left: 0;
    width: auto;
  }

  .tab__photo {
    height: 10vw;
    width: 10vw;
  }

  .tab h3 {
    font-size: 0.8rem;
  }

  .tab__content {
    padding: 20px;
  }

  .details {
    display: block;
  }

  .details__content {
    padding-right: 0;
  }

  .details__info {
    width: auto;
  }

  .menu {
    padding: 20px;
  }

  .calendar {
    height: auto;
  }

  body .rbc-month-view {
    display: block;
    flex: none;
    height: auto;
  }

  body .rbc-calendar {
    height: inherit;
  }

  body .rbc-row-segment .rbc-event-content {}

  body .rbc-month-row {
    display: block;
    flex: none;
    height: auto;
    min-height: auto;
  }

  .event {
    left: 5%;
    margin: 0;
    top: 80px;
    width: 90%;
  }

  .event__photo {
    display: none;
  }

  .payment--option {
    margin-top: 45px;
  }

  .payment__remove {
    display: block;
    margin: 25px auto 0 auto;
  }
}